
@media screen and (max-width: 520px) {
  /*　共通css */
  h2 {
    font-size: 22px;
  }

  /* ここまで */
  /* contact */
  .overlay {
    top: 40%;
    left: 20%;
  }
  
  .sp-contact-content  {
    font-size: 13px;

  }

  .contact-form {
    width: 100%;
  }

  .contact-content {
    font-size: 10px;

  }

  .row {
    margin-right: 0;
    margin-left: 0;
  }

  .border-right {
    background-color: #dbf7f1;
    padding: 10px;
  }
  /* ここまで */

  /* header */
  header {
    padding: 10px 0;
  }

  .header-menu-list {
    display: none;
  }

  button {
    border: none;
  }

  .menu-close-btn {
    position: fixed;
    top: 10px;
    right: 10px;
    display: flex;
    height: 45px;
    width: 45px;
    justify-content: center;
    align-items: center;
    z-index: 90;
    background-color: #3584bb;
  }

  .menu-close-btn span, .menu-close-btn span::before, .menu-close-btn span::after {
    content: '';
    display: block;
    height: 3px;
    width: 25px;
    border-radius: 3px;
    background-color: #fff;
    position: absolute;
  }
  .menu-close-btn span::before {
    bottom: 8px;
  }
  .menu-close-btn span::after {
    top: 8px;
  }

  /* ハンバーガーを押したら */

  .menu-open-btn {
    position: fixed;
    top: 10px;
    right: 10px;
    display: flex;
    height: 45px;
    width: 45px;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: #3584bb;

  }

  .menu-open-btn span, .menu-open-btn span::before, .menu-open-btn span::after {
    content: '';
    display: block;
    height: 3px;
    width: 25px;
    border-radius: 3px;
    background-color: #fff;
    position: absolute;
  }

  .menu-open-btn span {
    background-color: rgba(255, 255, 255, 0);
  }

  .menu-open-btn span::before {
    bottom: 0;
    transform: rotate(45deg);
  }

  .menu-open-btn span::after {
    top: 0;
    transform: rotate(-45deg);
  }

  .hamburger-menu {
    display: block;
    
  }

  .hamburger-menu-list {
    position: fixed;
    height: 100%;
    background-color: #3584bb;
    width: 100%;
    z-index: 999;
  }

  
  
  .open-menu-list li {
    border-bottom: 1px solid #f3f3f3;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
  }

  
  /* ここまで */

  /* TOP */

  .about-us {
    width: 80%;
    margin: 0 auto;
  }

  .slider-content {
    position: absolute;
    top: 40%;
    left: 10%;
  }
  
  .slider-content p {
    color: black;
    font-size: 10px;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  /* ここまで */

  /* Company */
  .access-map {
    text-align: center;
    width: 100%;
  }
  iframe {
    width: 300px;
  }
  /* ここまで */

  /* Business */

  .business-img {
    width: 100%;
    height: 306px;
  }

  /* ここまで */

  /* flow */
  .flow-contents {
    width: 100%;
    padding: 0;
  }

  .flow-main {
    width: 100%;
    border-radius: 8px;
  }

  .flow-contents span {
    font-size: 19px;
  }

  /* ここまで */

  /* footer */
  .footer-menu-list {
    display: block;
  }

  .footer-contents-list {
    width: 100%;
  }

  .footer-contents-list ul {
    padding: 5px 15px;
  }
  /* ここまで */
}