.voice-contents {
  width: 90%;
  position: relative;
  padding: 0.6em;
  background-color: tomato;
  color: white;
  border-radius: 5px;
}

.voice-contents::after {
  position: absolute;
  content: '';
  top: 100%;
  left: 30px;
  border: 12px solid transparent;
  border-top: 15px solid tomato;
  width: 0;
  height: 0;
}

.voice-list img {
  width: 80px;
  height: 80px;
}

