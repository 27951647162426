.flow-main {
  width: 70%;
}

.flow-contents {
  width: 70%;
  margin: 15px auto;
  border-radius: 8px;
  padding: 15px 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

}

.flow-contents p {
  color: white;
}

.flow-contents span {
  font-size: 24px;
  color: white;
  padding: 0 15px;
}

.flow-number {
  border: 1px solid;
  border-radius: 50%;
  padding: 5px 0;
  background-color: white;
}

.right-arrow {
  font-size: 24px;
  display: block;
}