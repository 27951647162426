

.access-map {
  width: 50%;
  margin: 0 auto;
}

.company-info {
  display: flex;
}

