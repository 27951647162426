
li {
  list-style: none;
}

.header-menu-list {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  /* justify-content: space-around; */
}

.header-menu-list li {
  padding: 5px 30px;
}

.header-menu {
  border-right: 1px solid black;
}

.hamburger-menu {
  display: none;
}

.title-height {
  height: 250px;
  background-color: darkgray;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-height h2 {
  color: white;
}

/* footer */
.footer-menu-list {
  display: flex;
  justify-content: space-around;
  padding: 30px 0;
  margin-bottom: 0;
}

footer {
  background-color: #f3f3f3;
}

.footer-menu-title {
  border-bottom: 1px solid silver;
  font-size: 22px;
  padding: 5px 0;
  margin-bottom: 10px;
}

.footer-contents-list {
  width: 30%;
}

/* 共通化css */

.content-style {
  margin: 40px 0;
  text-align: center;
}

.content-style p {
  
  padding: 20px 0;
}

/* Top css */

.slider-image {
  position: relative;
}

.slider-content {
  position: absolute;
  top: 70%;
  left: 10%;
}

.slider-content p {
  color: black;
  font-size: 25px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}
