.contact-top {
  position: relative;
}

.contact-form {
  margin: 0 auto;
  max-width: 960px;
}

.contact-form p{
  margin-top: 30px;
}

.ta-contact-content {
  width: 100%;
  height: 75%;
  margin-top: 15px;
}

.contact-title {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color:#16a085;
  height: 80px;
}

.contact-title h2 {
  color: white;
}

.contact-content {
  padding: 5px 0 5px 15px;
}

.contact-content span {
  font-size: 20px;
  font-weight: bold;
}

.required {
  position: absolute;
  top: 0;
  left: 80%;
  background-color: #ee6557;
  padding: 4px 8px 3px;
  /* margin-left: 10px; */
  line-height: 1;
  display: inline-block;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
}

.person-info-content {
  width: 100%;
  min-height: 140px;
  overflow-y: scroll;
  padding: 10px;
}

.person-info-agree {
  width: 100%;
  padding: 15px 0;
  margin: 30px 0 0 0;
  background-color: #f0f7fc;
  text-align: center;
}

.person-info-agree-btn {
  background-color: #ff9600;
  padding: 22px 85px 22px 89px;
  border-radius: 40px;
  box-shadow: 0px 2px 0px rgb(0 0 0 / 20%);
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  font-size: 20px;
  font-weight: bold;
  width: auto;
}

.person-info-agree-disable-btn {
  background-color: gray;
  padding: 22px 85px 22px 89px;
  border-radius: 40px;
  box-shadow: 0px 2px 0px rgb(0 0 0 / 20%);
  border: none;
  outline: none;
  cursor: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
  width: auto;
}