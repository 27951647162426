.case-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

.case-before img, .case-after img {
  transition: 0.4s ease;
  filter: brightness(60%) blur(1px);
}

.card-img {
  position: relative;
}
.card-title {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
}

.case-before img:hover, .case-after img:hover {
  filter: none;
}